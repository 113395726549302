import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { RichText } from 'prismic-reactjs'
import Layout from '../components/Layout'
import { Box, Button, Heading, Image, Grid, Stack, Text } from '@chakra-ui/react'
import { List, ListItem } from '@chakra-ui/react'
import EmailBookBox from '../components/EmailBookBox'
import css from '../components/slices/wysiwyg.module.scss'
import NewsletterModalBook from '../components/NewsletterModalBook'
import PortraitSofa from '../images/vitaly-malkin-portrait-sofa.jpg'
import OtherBooks from '../components/OtherBooks'
import { FormattedMessage, FormattedDate } from 'react-intl'
import useCalameoBook from '../hooks/useCalameoBook';
import CalameoBook from '../components/CalameoBook';

const BookLpEmail = ({ data, pageContext }) => {
  // console.log('books data', data )
  const pageContent = data.prismic.allBooks.edges[0]
  if (!pageContent) return null
  const page = pageContent.node
  const book = pageContent.node

  const topMenuContent = data.prismic.allTop_menus.edges[0]
  if (!topMenuContent) return null
  const topMenu = topMenuContent.node

  // const posts = data.prismic.allPosts.edges
  // const books = data.prismic.books.edges

  // console.log('data.pamphlets', data.pamphlets )

  const [isNewsletterFormVisible, setNewsletterFormVisible] = useState(false);

  if (page.pdf_document) {
    // console.log('PDF Url : ', page.pdf_document.url)
  }
  const ItemNumber = (props) => {
    return (
      <Box fontSize='100px' color='orange.400' opacity='.4' position='absolute' top='-40px' left='-30px'>{props.children}</Box>
    )
  }
  const CtaEmail = (props) => {
    return (
      <Box>
        <Button
          onClick={ () => { setNewsletterFormVisible( true ) }}
          fontFamily='Open Sans'
          fontSize='13px'
          textTransform='uppercase'
          color='white'
          bg='brand.orange'
          whiteSpace='pre-wrap'
          border='solid 1px'
          borderColor='brand.orange'
          w={props.w}
          _hover={{
            color: 'brand.orange',
            bg: 'white'
          }}
        >
          <FormattedMessage id='book.get.for.free' /> →
          </Button>
        <Text mt='.5rem' fontFamily='Open Sans' fontSize={{ base: '12px', lg: '13px' }}>
          <FormattedMessage id='lp.security.notice' />
        </Text>
      </Box>
    )
  }
  const Cta1 = (props) => {
    return (
      <Stack
        borderLeft='solid 3px'
        borderLeftColor='brand.orange'
        bg='gray.50'
        spacing='1rem'
        p={{ base: '1rem', lg: '2rem' }}
        w='100%'
      >
        <Box>
          <Text
            textTransform='uppercase'
          >
            <FormattedMessage id='lp.exclusive' />
          </Text>
          <Text>
            <FormattedMessage id='lp.limitied.offer.until' />{' '}
            <FormattedDate
              value={book.lp_limited_offer_date}
              year="numeric"
              month="long"
              day="numeric"
              weekday="long"
            />
            </Text>
        </Box>
        <Box>
          <CtaEmail />
        </Box>
      </Stack>
    )
  }
  if (page) {
    const pageTitle = page.title ? RichText.asText(page.title) : '';

    const calameoBookId = useCalameoBook(pageContext.calameoBooks, pageTitle);

    const bookLinks = data.prismicBook.data;

    return (
      <Layout
        topMenu={topMenu}
        activeDocMeta={page._meta}
        lang={pageContext.langIso}
        color="light"
        hideNav={true}
      >
        <Helmet>
          {page.seo_title ?
            <title>{RichText.asText(page.seo_title)}</title>
            : null}
          {page.seo_description ?
            <meta name="description" content={RichText.asText(page.seo_description)} />
            : null}
          {page.sharing_image ?
            <meta property="og:image" content={page.sharing_image.url} />
            : null}
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
        </Helmet>

        {/* { isNewsletterFormVisible ? <NewsletterModal onClose={() => { setNewsletterFormVisible(false) }} /> : null} */}
        {/* { isNewsletterFormVisible ? */}
          <NewsletterModalBook bookLinks={bookLinks} isOpen={ isNewsletterFormVisible } book={ book } handleClose = { () => {
            setNewsletterFormVisible( false )
            // console.log( 'close')
          }} />
        {/* : null } */}
        <Box mx='auto' maxW='1000px' py={{ lg: '4rem' }} px={{ lg: '1rem' }}>
          <Grid templateColumns={{ lg: '400px 1fr' }} gridGap={{ base: '2rem', lg: '5rem' }} >
            <Box bg='gray.50' p={{ lg:'2rem'}} pt={{ lg: '4rem' }}>
              <Stack spacing='1rem' position='sticky' top='2rem'>
                <Image
                  display={{ lg: 'none' }}
                  w={{ base:'100%', lg:'auto'}}
                  mx='auto'
                  objectFit='cover'
                  objectPosition='bottom'
                  src={page.cover_3d ? page.cover_3d.url : page.cover.url }
                  alt={page.cover.alt ? page.cover.alt : ''}
                  mb='0'
                  p='0'
                />
                <Image
                  display={{ base: 'none', lg: 'block' }}
                  w={{ base:'80%', lg:'auto'}}
                  mx='auto'
                  objectFit='cover'
                  objectPosition='bottom'
                  src={page.cover.medium ? page.cover.medium.url : page.cover.url }
                  alt={page.cover.alt ? page.cover.alt : ''}
                />
                <Box p={{ base:'1rem', lg:'0' }}>
                  <CtaEmail w='100%' />
                </Box>
              </Stack>
            </Box>
            <Stack spacing="3rem" p={{ base: '2rem', lg: '0rem' }} pt={{ lg: '4rem' }} shouldWrapChildren={true}>
              <Heading>
                {book.lp_quote}
              </Heading>
              <Cta1 />
              <Heading fontSize='22px'>
                {book.lp_sub_title}
              </Heading>
              {book.lp_arguments ?
                <List spacing='1rem' color='gray.700'>
                  {book.lp_arguments.map((_, index) =>
                    <ListItem
                      key={`LpArgs${index}`}
                    >
                      → {_.argument_item}
                    </ListItem>
                  )}
                </List>
                : null}

              <Box display={{ lg: 'none' }}>
                <CtaEmail />
              </Box>

                <Box display={{ base: 'none', lg: 'block' }} bg='gray.200' w='120px' h='3px' />
                <Heading fontSize='24px'>
                  {/* {`${book.lp_reasons.length}`}
                  {' '} */}
                  <FormattedMessage
                    id='lp.five.good.reasons'
                    values={{
                      count: book.lp_reasons.length,
                      title: RichText.asText(book.title)
                    }}
                  />
                </Heading>
                <List color='gray.700' spacing='2.5rem' fontSize='20px'>
                  {book.lp_reasons.map((_, index) =>

                    <ListItem
                      key={`LpReasons${index}`}
                      position='relative'>
                      <ItemNumber
                      >{index + 1}</ItemNumber>
                      {_.reason_item}
                    </ListItem>
                  )}
                </List>

              <Box display={{ base: 'none', lg: 'block' }} bg='gray.200' w='120px' h='3px' />
              <Box display={{ lg: 'none' }}>
                <CtaEmail />
              </Box>
            </Stack>
          </Grid>
          {calameoBookId && (
            <Box mt={{ base: '3rem' }}>
              <CalameoBook id={calameoBookId} />
            </Box>
          )}
          <Box my={{ base: '3.5rem', lg: '7.5rem' }}>
            <Grid templateColumns={{ lg: '250px 1fr' }} gap='4rem'>
              <Box display={{ base: 'none', lg: 'block' }}>
                <Image src={PortraitSofa} name='Vitaly Malkin' />
              </Box>
              <Stack px='2rem' spacing="1.5rem" shouldWrapChildren={true}>
                <Heading fontWeight='400'>
                  <FormattedMessage id='lp.an.exceptional' />
                </Heading>
                <Box
                  className={css.wysiwyg}
                  color='gray.700'
                >
                  <RichText render={book.lp_bio_content || []} />
                </Box>
                <CtaEmail />
              </Stack>
            </Grid>
          </Box>
        </Box>
        <Box display='none' mx='auto' maxW='1100px' py={{ lg: '4rem' }} px={{ lg: '1rem' }}>
          <Grid templateColumns={{ lg: '400px 1fr' }} gridGap={{ base: '2rem', lg: '5rem' }} >
            <Box>
              {page.cover_no_text ?
                <Image display={{ lg: 'none' }} w='100%' objectPosition='bottom' src={page.cover_no_text.url} alt={page.cover_no_text.alt ? page.cover_no_text.alt : ''} />
                : null}
              <Image display={{ base: 'none', lg: 'block' }} w='100%' objectFit='cover' objectPosition='bottom' src={page.cover.url} alt={page.cover.alt ? page.cover.alt : ''} />
            </Box>
            <Stack spacing={{ base: '1.5rem', lg: '3rem' }} mx={{ base: '2rem', lg: '0' }} >
              {pageTitle &&
                <Heading
                  as='h1'
                  fontWeight='400'
                  fontSize={{ base: '30px', lg: '42px' }}
                >
                  {pageTitle}
                </Heading>
              }
              {page.quote ?
                <Text
                  color='#3A3A3A'
                  fontFamily="Vesper Libre"
                  fontSize={{ base: '18px', lg: '22px' }}
                >
                  {RichText.asText(page.quote)}
                </Text>
                : null}
              {page.is_email_box_visible ?
                <Box minH='108px'>
                  <EmailBookBox
                    bookIdentifier={page.book_identifier}
                    bookPdfUrl={page.pdf_document.url}
                  />
                </Box>
                : null}
              <Box fontSize="18px" className={css.wysiwyg}
              >
                <RichText render={page.content || []} />
              </Box>

            </Stack>
          </Grid>
        </Box>
      </Layout>
    )
  }
  return null
}

export const query = graphql`
query queryBookLpEmail($uid: String, $lang: String) {
  prismicBook(uid: {eq: $uid}, lang: {eq: $lang}) {
    data {
      epub_link {
        url
      }
      pdf_link {
        url
      }
      audiobook_link {
        url
      }
    }
  }
  prismic {
    allBooks(
      uid: $uid,
      lang: $lang
    ) {
      edges {
        node {
          _meta {
            id
            uid
            type
            lang
            alternateLanguages {
              id
              lang
              type
              uid
            }
          }

          lp_quote
          lp_limited_offer_date
          lp_sub_title
          lp_arguments{
            argument_item
          }
          lp_reasons{
            reason_item
          }
          lp_bio_content
          lp_subtitle_2

          is_visible
          title
          book_identifier
          quote
          seo_title
          seo_description
          sharing_image
          cover
          cover_no_text
          cover_3d
          intro
          content
          is_email_box_visible
          pdf_document{
            _linkType
            ... on PRISMIC__FileLink{
              url
            }
          }
          amazon_link{
            _linkType
            ... on PRISMIC__ExternalLink{
              url
            }
          }       
          body{
            ... on PRISMIC_BookBodyQuote{
              type
              label
              primary{
                title1
              }
            }
            #... on PRISMIC_BookBodyEmail_book_box{
            #  type
            #  label
            #  primary{
            #    text_intro
            #    book_identifier
            #  }
            #}
            ... on PRISMIC_BookBodyFull_width_banner{
              type
              label
              primary{
                banner_title
              }
            }
            ... on PRISMIC_BookBodyInfo_with_image{
              type
              label
              primary{
                text
                featured_image
                text_intro
                book_identifier
                show_email_box
              }
            }
            ... on PRISMIC_BookBodyTwo_cols{
              type
              label
              primary{
                left_text
                right_text
                right_text_footer
                button_label
                button_target {
                  _linkType
                  __typename
                }
              }
            }
            ... on PRISMIC_BookBodyLatest_posts {
              type
              label
              primary{
                show_thumbnails
              }
            }
            ... on PRISMIC_BookBodyBooks {
              type
              label
            }
            ... on PRISMIC_BookBodyBio {
              type
              label
              primary{
                bio_content
                button_label
                button_target{
                  ... on PRISMIC__Document{
                    _meta {
                      uid
                      type
                      lang
                      alternateLanguages {
                        lang
                        type
                        uid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allPosts (
      lang: $lang
      sortBy:meta_firstPublicationDate_DESC
    ) {
      edges {
        node {
          _meta {
            uid
            type
            lang
            alternateLanguages {
              lang
              type
              uid
            }
          }
          title
          featured_image
          is_visible
        }
      }
    }
    books:allBooks (
      lang: $lang
      sortBy:meta_firstPublicationDate_DESC
    ) {
      edges {
        node {
          _meta {
            uid
            type
            lang
            alternateLanguages {
              lang
              type
              uid
            }
          }
          title
          cover
          amazon_link{
            _linkType
            ... on PRISMIC__ExternalLink{
              url
            }
          }      
        }
      }
    }
    allTop_menus(lang: $lang) {
      edges {
        node {
          menu_links {
            label
            link {
              ... on PRISMIC_Page {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
              ... on PRISMIC_Post {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
              ... on PRISMIC_Archive {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
              ... on PRISMIC_Homepage {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  pamphlets:
    prismic {
      allBooks(
        sortBy:meta_firstPublicationDate_DESC
        lang: $lang
        where: {is_pamphlet: true}
      ) {
        edges {
          node {
            title
            cover
            _meta{
              uid
            }
          }
        }
      }
    }
  
}

`

export default BookLpEmail
